import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";
import { RecoilRoot } from "recoil";
import theme from "./theme";
import App from "./App";
import "./index.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/audiowide/400.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { QueryClient, QueryClientProvider } from "react-query";
const queryClient = new QueryClient();

Sentry.init({
  dsn: "https://8b227783b4f5481387fc93e4246d18f4@o4504642286649344.ingest.sentry.io/4504952230641664",
  integrations: [new BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 1.0, // we will lower this number in prod
  replaysOnErrorSampleRate: 1.0,
  environment: import.meta.env.NODE_ENV,
  release: import.meta.env.VITE_APP_VERSION,
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <QueryClientProvider client={queryClient}>
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </RecoilRoot>
  </QueryClientProvider>
);
