import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    primary: {
      main: "#67C288",
    },
    secondary: {
      main: "#005587",
    },
    error: {
      main: red[600],
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          maxWidth: "100%",
        },
      },
    },
  },
});

export default theme;
