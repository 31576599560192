import { Route } from "./routes-props";
import { lazy } from "react";

const EmailVerification = lazy(() => import("../pages/email-verification"));
const PersonalInfo = lazy(() => import("../pages/personal-info"));
const BusinessInfo = lazy(() => import("../pages/business-info"));
const BankingInfo = lazy(() => import("../pages/banking-info"));
const PendingApproval = lazy(() => import("../pages/pending-approval"));
const ManualApproval = lazy(() => import("../pages/manual-approval"));
const ExistingApplication = lazy(() => import("../pages/existing-application"));
const ManualReview = lazy(() => import("../pages/manual-review"));
const DeclinedApplication = lazy(() => import("../pages/declined-application"));
const ApprovedApplication = lazy(() => import("../pages/approved-application"));
const Summary = lazy(() => import("../pages/summary"));
const Confirmation = lazy(() => import("../pages/confirmation"));
const CebaAmountCalculation = lazy(() => import("../pages/ceba-amount"));
const CebaCommitment = lazy(() => import("../pages/ceba-commitment"));
const CebaBankingInfo = lazy(() => import("../pages/ceba-banking-info"));
const AbandonedApplication = lazy(
  () => import("../pages/abandoned-application")
);
const ErrorApplication = lazy(() => import("../pages/error-application"));
const IdVerification = lazy(() => import("../pages/id-verification"));
const Agreement = lazy(() => import("../pages/agreement"));
const Acknowledgement = lazy(() => import("../pages/acknowledgement"));

const routes: Route[] = [
  {
    path: "/",
    name: "Email Verification",
    component: EmailVerification,
    exact: true,
  },
  {
    path: "/personal-info",
    name: "Personal Information",
    component: PersonalInfo,
    exact: true,
  },
  {
    path: "/business-info",
    name: "Business Information",
    component: BusinessInfo,
    exact: true,
  },
  {
    path: "/ceba-amount",
    name: "CEBA Loan Amount",
    component: CebaAmountCalculation,
    exact: true,
  },
  {
    path: "/ceba-banking-info",
    name: "CEBA Banking Information",
    component: CebaBankingInfo,
    exact: true,
  },
  {
    path: "/banking-info",
    name: "Banking Information",
    component: BankingInfo,
    exact: true,
  },
  {
    path: "/pending-approval",
    name: "Pending Approval",
    component: PendingApproval,
    exact: true,
  },
  {
    path: "/manual-approval",
    name: "Manual Approval",
    component: ManualApproval,
    exact: true,
  },
  {
    path: "/existing-application",
    name: "Existing Application",
    component: ExistingApplication,
    exact: true,
  },
  {
    path: "/manual-review",
    name: "Manual Review",
    component: ManualReview,
    exact: true,
  },
  {
    path: "/declined-application",
    name: "Declined Application",
    component: DeclinedApplication,
    exact: true,
  },
  {
    path: "/approved-application",
    name: "Approved Application",
    component: ApprovedApplication,
    exact: true,
  },
  {
    path: "/ceba-commitment",
    name: "Ceba Commitment",
    component: CebaCommitment,
    exact: true,
  },
  {
    path: "/abandoned-application",
    name: "Abandoned Application",
    component: AbandonedApplication,
    exact: true,
  },
  {
    path: "/error-application",
    name: "Error Application",
    component: ErrorApplication,
    exact: true,
  },
  {
    path: "/summary",
    name: "Summary",
    component: Summary,
    exact: true,
  },
  {
    path: "/id-verification",
    name: "Id Verification",
    component: IdVerification,
    exact: true,
  },
  {
    path: "/acknowledgement",
    name: "Acknowledgement",
    component: Acknowledgement,
    exact: true,
  },
  {
    path: "/agreement",
    name: "Agreement",
    component: Agreement,
    exact: true,
  },
  {
    path: "/confirmation",
    name: "Confirmation",
    component: Confirmation,
    exact: true,
  },
];

export default routes;
