import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import routes from "./routes/routes";
import { MemoryRouter as Router, Route, Routes } from "react-router-dom";
import SvgComponent from "./components/animated-logo";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Suspense, useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Loading from "./components/loading";
import { useRecoilValue } from "recoil";
import { useIdleTimer } from "react-idle-timer";
import { checkIfTokenIsExpired } from "./utils";
import axios from "axios";
import { personalInfoState } from "./recoil/personal-info/PersonalInfoFormState";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

const App = () => {
  const [show, setShow] = useState(true);
  const expired = localStorage.getItem("expired");
  const token = localStorage.getItem("token");
  const personalInfo = useRecoilValue(personalInfoState);
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));
  const [open, setOpen] = useState(expired === "true");

  const handleOnIdle = () => {
    if (token) {
      handleLogout();
    }
  };

  const handleClose = () => {
    localStorage.removeItem("expired");
    setOpen(false);
  };

  useEffect(() => {
    (async () => {
      if (token && !checkIfTokenIsExpired(token)) {
        axios.defaults.headers.common["Authorization"] = token;
        reset();
      }
    })();
  }, [personalInfo]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.setItem("expired", "true");
    window.location.href = "/";
  };

  const { reset } = useIdleTimer({
    timeout: 1000 * 60 * 10,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  useEffect(() => {
    let timer = setTimeout(() => setShow(false), 1500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        height: `${window.innerHeight}px`,
        backgroundColor: "#F0F0F0",
      }}
    >
      {show ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: `${window.innerHeight}px`,
            mx: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              bgcolor: "#F0F0F0",
              p: 3,
              borderRadius: "4px",
            }}
          >
            <SvgComponent width={desktop ? 500 : 300} />
          </Box>
        </Box>
      ) : (
        <>
          <AppBar position="fixed" sx={{ zIndex: 9999 }}>
            <Toolbar sx={{ bgcolor: "white", py: 1 }}>
              <img height={40} src="logo-full.svg" />
            </Toolbar>
          </AppBar>
          <Router
            initialEntries={
              window.location.pathname === "/summary" ? ["/summary"] : ["/"]
            }
          >
            <Suspense
              fallback={
                <Backdrop open={true} sx={{ position: "fixed", zIndex: 9999 }}>
                  <Loading size={100} />
                </Backdrop>
              }
            >
              <Routes>
                {routes.map((route) => (
                  <Route
                    path={route.path}
                    key={route.path}
                    element={
                      route.path === "/" ? (
                        <route.component />
                      ) : (
                        <Dialog
                          open={true}
                          maxWidth="sm"
                          fullWidth
                          scroll="body"
                          hideBackdrop={true}
                          sx={{
                            "& .MuiDialog-container": {
                              justifyContent: "flex-start",
                              alignItems: "flex-start",
                            },
                          }}
                          PaperProps={{
                            sx: {
                              mt: { xs: 12, md: 14 },
                            },
                          }}
                        >
                          <route.component />
                        </Dialog>
                      )
                    }
                  />
                ))}
              </Routes>
            </Suspense>
          </Router>
        </>
      )}
      <Snackbar
        open={open}
        autoHideDuration={9999999999999}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          You were logged out as your session expired
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default App;
