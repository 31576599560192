import Box from "@mui/material/Box";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import loader from "../../assets/loader.json";

const Loading = ({ size, style }: { size?: number; style?: any }) => {
  return (
    <Box sx={style}>
      <Lottie
        animationData={loader}
        loop
        style={{ height: size, width: "100%" }}
        play
      />
    </Box>
  );
};

export default Loading;
