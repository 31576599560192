export const isDeepEqual = (object1: any, object2: any) => {
  const objKeys1 = Object.keys(object1);
  const objKeys2 = Object.keys(object2);

  if (objKeys1.length !== objKeys2.length) return false;

  for (var key of objKeys1) {
    const value1 = object1[key];
    const value2 = object2[key];

    const isObjects = isObject(value1) && isObject(value2);

    if (
      (isObjects && !isDeepEqual(value1, value2)) ||
      (!isObjects && value1 !== value2)
    ) {
      return false;
    }
  }
  return true;
};

export const checkIfTokenIsExpired = (token: string) => {
  const { payload } = jwtDecode<{ exp: number }>(token);
  const expired = Date.now() >= payload.exp * 1000;
  if (expired) localStorage.removeItem("token");
  return expired;
};

const jwtDecode = <T,>(token: string) => {
  const header = JSON.parse(window.atob(token.split(".")[0]));
  const payload = JSON.parse(window.atob(token.split(".")[1])) as T;
  return { raw: token, header, payload };
};

const isObject = (object: any) => {
  return object != null && typeof object === "object";
};
